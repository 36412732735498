import { Injectable } from '@angular/core';

import { ListRequest, ListResponse, Customer, UserTag, CommunityMember } from 'src/models';

import { ApiService } from './api.service';

const BASE_URL = '/customers';

interface UpsertCommunityMemberOptions {
  customerId: string;
  userId?: string;
  email?: string;
  phoneNumber?: string;
  name?: string;
  notes?: string;
  muteWelcomeEmail?: boolean;
  customFields?: any;
  following?: boolean;
  notifications?: any;
  isExternal?: boolean;
}

interface BulkUpdateCommunityMembersOptions {
  customerId: string;
  userIds: string[];
  following?: boolean;
  isExternal?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  constructor(private apiService: ApiService) {}

  create(name: string, slackChannelId?: string): Promise<Customer> {
    return this.apiService.postPromise(`${BASE_URL}`, {
      name,
      slackChannelId,
    });
  }

  update(customerId: string, updates: any): Promise<void> {
    return this.apiService.patchPromise(`${BASE_URL}/${customerId}`, updates);
  }

  bulkCreate(channels: Record<string, string>, isInternalOverride?: boolean, teamId?: string): Promise<string[]> {
    return this.apiService.postPromise(`${BASE_URL}/bulk`, {
      channels,
      isInternalOverride,
      teamId,
    });
  }

  list(options: ListRequest = {}): Promise<ListResponse<Partial<Customer>>> {
    return this.apiService.postPromise(`${BASE_URL}/list`, options);
  }

  get(customerId: string) {
    return this.apiService.getPromise(`${BASE_URL}/${customerId}`);
  }

  async delete(customerId: string) {
    return this.apiService.deletePromise(`${BASE_URL}/${customerId}`);
  }

  async listUserTagsForCustomer(customerId: string): Promise<UserTag[]> {
    const response = await this.apiService.postPromise<{ data: UserTag[] }>(
      `${BASE_URL}/${customerId}/user-tags/list`,
      {
        limit: null,
        order: ['createdAt'],
        descending: true,
      },
    );

    return response.data;
  }

  async createUserTag(customerId: string, name: string, rule?: any): Promise<UserTag> {
    const response = await this.apiService.postPromise(`${BASE_URL}/${customerId}/user-tags`, {
      name,
      rule,
    });

    return response as UserTag;
  }

  async updateUserTag(customerId: string, id: string, name: string, rule?: any): Promise<UserTag> {
    const response = await this.apiService.putPromise(`${BASE_URL}/${customerId}/user-tags/${id}`, {
      name,
      rule,
    });

    return response as UserTag;
  }

  async deleteUserTag(customerId: string, id: String): Promise<any> {
    const response = await this.apiService.deletePromise(`${BASE_URL}/${customerId}/user-tags/${id}`);

    return response;
  }

  async getUserTag(customerId: string, id: String): Promise<UserTag> {
    const response = await this.apiService.getPromise(`${BASE_URL}/${customerId}/user-tags/${id}`);

    return response as UserTag;
  }

  async getUserTagLinks(customerId: string): Promise<any> {
    const response = await this.apiService.getPromise(`${BASE_URL}/${customerId}/user-tag-links`);

    return response;
  }

  async createUserTagLinks(customerId: string, id: string, userIds: string[]): Promise<any> {
    const response = await this.apiService.postPromise(
      `${BASE_URL}/${customerId}/user-tags/${id}/create-links`,
      userIds,
    );

    return response;
  }

  async deleteUserTagLinks(customerId: string, id: string, userIds: string[]): Promise<any> {
    const response = await this.apiService.postPromise(
      `${BASE_URL}/${customerId}/user-tags/${id}/delete-links`,
      userIds,
    );

    return response;
  }

  async upsertCustomerMember({
    customerId,
    userId,
    email,
    phoneNumber,
    name,
    notes,
    muteWelcomeEmail,
    customFields,
    following,
    notifications,
    isExternal,
  }: UpsertCommunityMemberOptions) {
    return this.apiService.postPromise(`${BASE_URL}/${customerId}/members`, {
      userId,
      email,
      phoneNumber,
      name,
      following,
      notes,
      muteWelcomeEmail,
      customFields,
      notifications,
      isExternal,
    });
  }

  async bulkUpdateCustomerMembers({ customerId, userIds, following, isExternal }: BulkUpdateCommunityMembersOptions) {
    return this.apiService.patchPromise(`${BASE_URL}/${customerId}/members/bulk`, {
      userIds,
      following,
      isExternal,
    });
  }

  async getFollowers(customerId: string): Promise<CommunityMember[]> {
    const communityMembers: CommunityMember[] = (await this.apiService.getPromise(
      `${BASE_URL}/${customerId}/members`,
    )) as any;

    return communityMembers;
  }
}
